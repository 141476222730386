:root {
  background-color: transparent;
}

html,
body,
#root {
  height: 100%;
  position: relative;
  width: 100%;
}
body {
  margin: 0;
}
svg {
  fill: currentColor;
}
